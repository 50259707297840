import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './Home';
import FullMap from './FullMap';
import NotFound from './NotFound';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route index element={<Home />} />
                    <Route path="fullmap" element={<FullMap />} />
                    {/* <Route path="contact" element={<Contact />} /> */}
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
