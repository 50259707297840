import { useState } from "react";
import styled from "styled-components";
import {
    Container,
    Wrapper,
    ContainerWrapper,
    ImageBox,
    Image,
    Title,
    Description,
    RoadmapWrapper,
    Box,
    DescriptionLight,
    Line,
} from "./faqStyle";

const AccordionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--Secondary-color-dark);
    border-radius: 10px;
    height: auto;
    padding: 2%;
    transition: all 1s ease-in;
`;

const InternalWrapper = styled.div`
    width: 100%;
    max-height: ${(props) => (props.open ? "200px" : "0")};
    transition: all 0.5s ease-in-out;
    overflow: hidden;
`;

const Faq = () => {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClick2 = () => {
        setOpen2(!open2);
    };
    const handleClick3 = () => {
        setOpen3(!open3);
    };
    const handleClick4 = () => {
        setOpen4(!open4);
    };

    return (
        <Wrapper>
            <Container>
                <ContainerWrapper>
                    <Title>FAQ</Title>
                    {/* 1 */}
                    <AccordionWrapper>
                        <img src="../../config/images/line.webp" alt="faq" />
                        <Box>
                            <Description>HOW CAN I GET A KUNO?</Description>
                            <Image
                                src="../../config/images/add.webp"
                                alt="add button"
                                onClick={handleClick}
                            />
                        </Box>

                        <InternalWrapper open={open}>
                            <DescriptionLight>
                                We will be minting through our website ONLY.{" "}
                                <br /> If you miss the mint we will be on Open
                                Sea for secondary sales.
                            </DescriptionLight>
                        </InternalWrapper>
                    </AccordionWrapper>
                    {/* 2 */}
                    <AccordionWrapper>
                        <img src="../../config/images/line.webp" alt="faq" />
                        <Box>
                            <Description>IS THERE A WHITELIST?</Description>
                            <Image
                                src="../../config/images/add.webp"
                                alt="add button"
                                onClick={handleClick2}
                            />
                        </Box>

                        <InternalWrapper open={open2}>
                            <DescriptionLight>
                                Yes! Whitelist will be rewarded to active and
                                friendly members. Please see our Discord
                                announcements for more detail.
                            </DescriptionLight>
                        </InternalWrapper>
                    </AccordionWrapper>
                    {/* 3 */}
                    <AccordionWrapper>
                        <img src="../../config/images/line.webp" alt="faq" />
                        <Box>
                            <Description>WHEN WILL THE KUNO DROP?</Description>
                            <Image
                                src="../../config/images/add.webp"
                                alt="add button"
                                onClick={handleClick3}
                            />
                        </Box>

                        <InternalWrapper open={open3}>
                            <DescriptionLight>
                                The public sale will begin on TBA. Price and
                                Date TBA. <br />
                                For public sale you will be able to mint 5 NFT
                                max for One Wallet
                            </DescriptionLight>
                        </InternalWrapper>
                    </AccordionWrapper>
                    {/* 4 */}
                    <AccordionWrapper>
                        <img src="../../config/images/line.webp" alt="faq" />
                        <Box>
                            <Description>
                                HOW MANY KUNOS WILL BE THERE
                            </Description>
                            <Image
                                src="../../config/images/add.webp"
                                alt="add button"
                                onClick={handleClick4}
                            />
                        </Box>

                        <InternalWrapper open={open4}>
                            <DescriptionLight>
                                There will be a total of 10,010 unique KUNOS
                                will ever exist. <br />
                                10 of them will be one on one drop, Super Super
                                rare!
                            </DescriptionLight>
                        </InternalWrapper>
                    </AccordionWrapper>
                    <img
                        style={{ marginTop: "5rem", width: "100%" }}
                        src="../../config/images/Divider.webp"
                        alt="Divider"
                    />
                </ContainerWrapper>
            </Container>
        </Wrapper>
    );
};

export default Faq;
