import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import About from "./components/about/About";
import Note from "./components/note/Note";
import Roadmap from "./components/roadmap/Roadmap";
import Faq from "./components/faq/Faq";
import Team from "./components/team/Team";

const truncate = (input, len) =>
    input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
    font-size: 25px;
    padding: 15px;
    border: none;
    background-color: #9b673b;

    font-weight: bold;
    color: #ffe9bf;
    width: 400px;
    cursor: pointer;
    box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
    -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
    -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
    position: relative;
    z-index: 1000;
    :active {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }

    @media (max-width: 1200px) {
        width: 300px;
        font-size: 19px;
        padding: 15px;
    }

    @media (max-width: 900px) {
        width: 200px;
        font-size: 19px;
        padding: 15px;
    }

    @media (max-width: 768px) {
        width: 260px;
        font-size: 19px;
        padding: 5px;
    }

    @media (max-width: 480px) {
        padding: 10px;
        font-weight: normal;

        width: 200px;
        font-size: 20px;
    }
`;

export const StyledRoundButton = styled.button`
    font-size: 25px;
    padding: 10px;
    border: none;
    background-color: #9b673b;
    padding: 10px;
    font-weight: bold;
    color: #ffe9bf;
    height: 40px;
    width: 80px;
    cursor: pointer;
    box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
    -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
    -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
    position: relative;
    z-index: 1000;
    :active {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }
    @media (max-width: 1200px) {
        height: 40px;
        width: 70px;
        font-size: 19px;
        padding: 15px;
    }

    @media (max-width: 900px) {
        height: 40px;
        width: 60px;
        font-size: 19px;
        padding: 10px;
    }

    @media (max-width: 768px) {
        height: 40px;
        width: 60px;
        font-size: 23px;
    }

    @media (max-width: 480px) {
        height: 30px;
        width: 40px;
        font-size: 20px;
    }
`;

export const ResponsiveWrapper = styled.div`
    display: flex;

    flex-direction: row;
    justify-content: stretched;
    align-items: stretched;
    width: 100%;
    position: relative;
    @media (min-width: 768px) {
        flex-direction: row;
    }
`;

export const ContainerGif = styled.div`
    @media (min-width: 767px) {
        position: absolute;
        right: ${({ flex }) => (flex ? flex : 0)};
    }
`;

export const GifRight = styled.img`
    position: absolute;
    width: 160px;
    top: 230px;
    right: 300px;
    z-index: 1000;

    @media (max-width: 1350px) {
        top: 230px;
        right: 200px;
        width: 170px;
    }

    @media (max-width: 1050px) {
        top: 230px;
        right: 50px;
        width: 170px;
    }

    @media (max-width: 768px) {
        width: 140px;
        right: 0px;
        top: 150px;
    }

    @media (max-width: 480px) {
        display: none;
    }
`;

export const GifLeft = styled.img`
    position: absolute;
    width: 160px;
    top: 230px;

    left: 300px;
    z-index: 1000;

    @media (max-width: 1350px) {
        top: 230px;
        left: 200px;
        width: 170px;
    }

    @media (max-width: 1050px) {
        top: 230px;
        left: 50px;
        width: 170px;
    }

    @media (max-width: 768px) {
        width: 140px;

        top: 150px;
        left: 0px;
    }

    @media (max-width: 480px) {
        display: none;
    }
`;

export const KunoGif = styled.video`
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 3px solid #4e392d;

    @media (min-width: 480px) {
        display: none;
    }
`;

export const StyledNavbar = styled.div`
    padding: 12px 100px 0 100px;
    // margin-top: 20px;
    width: 1100px;
    height: 100px;
    background-image: ${({ image }) => (image ? `url(${image})` : "none")};
    // background-image: url('/config/images/Navbar.webp');
    position: relative;
    background-size: cover;
    background-position: center;
    @media (max-width: 1200px) {
        width: 900px;
        height: 80px;
    }
    @media (max-width: 960px) {
        width: 700px;
        height: 60px;
        padding: 8px 70px 0 70px;
    }
    @media (max-width: 600px) {
        margin-top: 0px;
        padding: 13px 20px 0 20px;

        width: 100%;
        height: 80px;
    }
`;

export const StyledMenu = styled.ul`
    display: flex;

    @media (max-width: 480px) {
        display: none;
    }
`;
export const StyledMenuItem = styled.a`
    font-size: 32px;
    font-family: "Caesar Dressing", sans-serif;
    color: #ffe9bf;
    text-decoration: none;

    &:first-child {
        margin-right: 35px;
    }
    &:last-child {
        margin-left: 35px;
    }

    @media (max-width: 960px) {
        font-size: 23px;
    }

    @media (max-width: 768px) {
        font-size: 20px;
        &:first-child {
            margin-right: 15px;
        }
        &:last-child {
            margin-left: 15px;
        }
    }

    @media (max-width: 480px) {
        font-size: 17px;
        &:first-child {
            margin-left: auto;
        }
        &:last-child {
            margin-left: 15px;
        }
    }
`;

export const StyledMenuMobile = styled.div`
    display: none;
    color: #ffe9bf;
    border-radius: 10px;
    height: auto;
    // padding: 2%;
    transition: all 350ms cubic-bezier(0.575, 0.19, 0.85, 0.41); /* custom */
    transition-timing-function: cubic-bezier(0.575, 0.19, 0.85, 0.41);

    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
        // justify-content: center;
    }
`;

export const MobileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    z-index: 1001;
    width: 100%;
    height: ${(props) => (props.open ? "1400px" : "0")};
    // transition: all 0.5s ease-in-out;
    transition: all 500ms cubic-bezier(0.25, 0.25, 0.75, 0.75); /* linear */
    transition-timing-function: cubic-bezier(
        0.25,
        0.25,
        0.75,
        0.75
    ); /* linear */
    overflow: hidden;
    background-color: #976439;
    color: #ffe9bf;
`;

export const ItemMobile = styled.a`
    font-size: 28px;
    font-family: "Caesar Dressing", sans-serif;
    color: #ffe9bf;
    text-decoration: none;
    margin: 0.5rem 1.5rem;

    :first-child {
        margin-top: 4rem;
    }
`;

export const StyledLogo = styled.img`
    // display: none;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;

    margin-left: auto;
    margin-right: auto;
    width: 170px;
    transition: width 0.5s;
    transition: height 0.5s;
    @media (max-width: 768px) {
        width: 100px;
    }

    @media (max-width: 480px) {
        // margin-left: 0;
        // left: 0;
        width: 120px;
    }
`;

export const StyledImg = styled.img`
    width: 130px;
    @media (min-width: 900px) {
        width: 180px;
    }
    @media (min-width: 1000px) {
        width: 180px;
    }
    transition: width 0.5s;
`;

export const StyledLink = styled.a`
    color: var(--secondary);
    text-decoration: none;
`;

function Home() {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
    const [mintAmount, setMintAmount] = useState(1);

    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
    });
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        // console.log('test');
        setOpen(!open);
    };

    const claimNFTs = () => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        blockchain.smartContract.methods
            .mint(mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once("error", (err) => {
                console.log(err);
                setFeedback(
                    "Sorry, something went wrong please try again later."
                );
                setClaimingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                setFeedback(
                    `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    };

    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
    };

    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > 5) {
            newMintAmount = 5;
        }
        setMintAmount(newMintAmount);
    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    return (
        <>
            <s.Screen
                image={
                    CONFIG.SHOW_BACKGROUND ? "/config/images/map.webp" : null
                }
            >
                <s.Container
                    flex={1}
                    ai={"center"}
                    style={{ padding: 0 }}
                    // image={
                    //     CONFIG.SHOW_BACKGROUND ? '/config/images/map.webp' : null
                    // }
                >
                    <StyledNavbar image={"/config/images/navbar.webp"}>
                        <StyledMenu>
                            <StyledMenuItem
                                href="https://twitter.com/KunoSpiritWorld"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Twitter
                            </StyledMenuItem>
                            <StyledMenuItem
                                href="https://opensea.io/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                OpenSea
                            </StyledMenuItem>
                            <s.MarginRight></s.MarginRight>
                            <StyledMenuItem href="#roadmap">
                                Roadmap
                            </StyledMenuItem>
                            <StyledMenuItem href="#about">About</StyledMenuItem>
                        </StyledMenu>
                        {/* for mobie menu */}
                        <StyledMenuMobile>
                            <div>
                                {" "}
                                <a href="">
                                    <img
                                        style={{ width: "27px" }}
                                        src="/config/images/opensea-icon.webp"
                                    />
                                </a>
                                <a
                                    href="https://twitter.com/KunoSpiritWorld"
                                    style={{ marginLeft: 10 }}
                                >
                                    <img
                                        style={{
                                            width: "27px",
                                            marginBottom: 2,
                                        }}
                                        src="/config/images/twitter-icon.webp"
                                    />
                                </a>
                            </div>

                            <div
                                style={{
                                    alignSelf: "flex-end",
                                    cursor: "pointer",
                                    position: "absolute",
                                    zIndex: 2000,
                                }}
                                onClick={handleClick}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
                                </svg>
                            </div>
                        </StyledMenuMobile>

                        <StyledLogo
                            alt={"logo"}
                            src={"/config/images/logo.webp"}
                        />
                    </StyledNavbar>
                    <MobileWrapper open={open}>
                        <ItemMobile
                            href="https://twitter.com/KunoSpiritWorld"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Twitter
                        </ItemMobile>
                        <ItemMobile
                            href="https://twitter.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            OpenSea
                        </ItemMobile>
                        <ItemMobile onClick={handleClick} href="#about">
                            About
                        </ItemMobile>
                        <ItemMobile onClick={handleClick} href="#roadmap">
                            Roadmap
                        </ItemMobile>
                    </MobileWrapper>

                    <s.SpacerSmall />
                    {/* <s.SpacerSmall /> */}
                    {/* <s.SpacerSmall />
                    <s.SpacerSmall /> */}

                    <ResponsiveWrapper style={{ padding: 24 }} test>
                        <GifLeft src="/config/images/hitodama.gif" alt="gif" />
                        <GifRight src="/config/images/hitodama.gif" alt="gif" />

                        <s.ContainerGif jc={"center"} ai={"center"} style={{}}>
                            {/* <StyledImg
                                alt={'example'}
                                src={'/config/images/Hitodama.gif'}
                            /> */}
                        </s.ContainerGif>
                        <s.SpacerLarge />
                        <s.ContainerMinting
                            flex={3}
                            jc={"center"}
                            ai={"center"}
                            // image={
                            //     CONFIG.SHOW_BACKGROUND
                            //         ? '/config/images/minting-menu.webp'
                            //         : null
                            // }
                        >
                            <s.MintingBackground src="/config/images/minting-menu.webp" />

                            <KunoGif autoPlay loop muted playsInline>
                                <source
                                    src="/config/images/kuno-mobile.webm"
                                    type="video/webm"
                                />
                                <source
                                    src="/config/images/kuno-mobile.mp4"
                                    type="video/mp4"
                                />
                            </KunoGif>
                            {/* <ImageGif src="/config/images/kuno-gif-mobile.gif" /> */}
                            <s.MobileTitle
                                style={{
                                    textAlign: "center",
                                    fontWeight: "800",
                                    color: "#54483E",
                                }}
                            >
                                Public Sale begins <br />
                                TBA
                            </s.MobileTitle>
                            <s.TextTitle
                                style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    color: "#54483E",
                                    marginTop: "20px",
                                }}
                            >
                                {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                            </s.TextTitle>
                            {/* PINK LINK like this 0x827acb09a2dc2 */}
                            {/* <s.TextDescription
                                style={{
                                    textAlign: 'center',
                                    color: 'var(--primary-text)',
                                }}
                            >
                                <StyledLink
                                    target={'_blank'}
                                    href={CONFIG.SCAN_LINK}
                                >
                                    {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                                </StyledLink>
                            </s.TextDescription> */}
                            {/* <s.SpacerSmall /> */}
                            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                                <>
                                    <s.TextTitle
                                        style={{
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: "#54483E",
                                        }}
                                    >
                                        The sale has ended.
                                    </s.TextTitle>
                                    <s.TextDescription
                                        style={{
                                            textAlign: "center",
                                            fontSize: 40,
                                            fontWeight: "bold",
                                            color: "#54483E",
                                        }}
                                    >
                                        You can still find {CONFIG.NFT_NAME} on
                                    </s.TextDescription>
                                    <s.SpacerSmall />
                                    <StyledLink
                                        target={"_blank"}
                                        href={CONFIG.MARKETPLACE_LINK}
                                    >
                                        {CONFIG.MARKETPLACE}
                                    </StyledLink>
                                </>
                            ) : (
                                <>
                                    <s.TextTitle
                                        style={{
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: "#54483E",
                                        }}
                                    >
                                        Price TBA
                                        {/* 1 {CONFIG.SYMBOL} costs{" "}
                                        {CONFIG.DISPLAY_COST}{" "}
                                        {CONFIG.NETWORK.SYMBOL}. */}
                                    </s.TextTitle>
                                    {/* <s.SpacerXSmall /> */}
                                    <s.TextDescription
                                        style={{
                                            color: "#54483E",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        Excluding gas fees.
                                    </s.TextDescription>
                                    {blockchain.account === "" ||
                                    blockchain.smartContract === null ? (
                                        <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                        >
                                            <s.TextDescription
                                                style={{
                                                    color: "#54483E",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Connect to the{" "}
                                                {CONFIG.NETWORK.NAME} network
                                            </s.TextDescription>
                                            <s.SpacerSmall />
                                            <StyledButton
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    dispatch(connect());
                                                    getData();
                                                }}
                                            >
                                                CONNECT
                                            </StyledButton>
                                            {blockchain.errorMsg !== "" ? (
                                                <>
                                                    <s.TextDescription
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#ff0000",
                                                        }}
                                                    >
                                                        {blockchain.errorMsg}
                                                    </s.TextDescription>
                                                </>
                                            ) : null}
                                        </s.Container>
                                    ) : (
                                        <>
                                            <s.TextDescription
                                                style={{
                                                    textAlign: "center",
                                                    color: "#54483E",
                                                }}
                                            >
                                                {feedback}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <s.Container
                                                ai={"center"}
                                                jc={"center"}
                                                fd={"row"}
                                            >
                                                {/* min */}
                                                <StyledRoundButton
                                                    style={{ lineHeight: 0.4 }}
                                                    disabled={
                                                        claimingNft ? 1 : 0
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        decrementMintAmount();
                                                    }}
                                                >
                                                    -
                                                </StyledRoundButton>
                                                <s.SpacerMedium />
                                                {/* jumlah  */}
                                                <s.TextDescription
                                                    style={{
                                                        textAlign: "center",
                                                        color: "#54483E",
                                                    }}
                                                >
                                                    {mintAmount}
                                                </s.TextDescription>
                                                <s.SpacerMedium />
                                                {/* tambah */}
                                                <StyledRoundButton
                                                    style={{ lineHeight: 0.4 }}
                                                    disabled={
                                                        claimingNft ? 1 : 0
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        incrementMintAmount();
                                                    }}
                                                >
                                                    +
                                                </StyledRoundButton>
                                            </s.Container>
                                            <s.SpacerSmall />
                                            <s.Container
                                                ai={"center"}
                                                jc={"center"}
                                                fd={"row"}
                                            >
                                                <StyledButton
                                                    disabled={
                                                        claimingNft ? 1 : 0
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        claimNFTs();
                                                        getData();
                                                    }}
                                                >
                                                    {claimingNft
                                                        ? "BUSY"
                                                        : "BUY"}
                                                </StyledButton>
                                            </s.Container>
                                        </>
                                    )}
                                </>
                            )}
                            <s.SpacerMedium />
                        </s.ContainerMinting>
                        <s.SpacerLarge />
                        <s.ContainerGif jc={"center"} ai={"center"} style={{}}>
                            {/* <StyledImg
                                alt={'example'}
                                src={'/config/images/Hitodama.gif'}
                                style={{ transform: 'scaleX(-1)' }}
                            /> */}
                        </s.ContainerGif>
                    </ResponsiveWrapper>
                    <s.SpacerMedium />
                    {/* <s.Container
                        jc={'center'}
                        ai={'center'}
                        style={{ width: '70%' }}
                    >
                        <s.TextDescription
                            style={{
                                textAlign: 'center',
                                color: 'var(--primary-text)',
                            }}
                        >
                            Please make sure you are connected to the right
                            network ({CONFIG.NETWORK.NAME} Mainnet) and the
                            correct address. Please note: Once you make the
                            purchase, you cannot undo this action.
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <s.TextDescription
                            style={{
                                textAlign: 'center',
                                color: 'var(--primary-text)',
                            }}
                        >
                            We have set the gas limit to {CONFIG.GAS_LIMIT} for
                            the contract to successfully mint your NFT. We
                            recommend that you don't lower the gas limit.
                        </s.TextDescription>
                    </s.Container> */}
                </s.Container>
            </s.Screen>
            <About />
            <Note />
            <Roadmap />
            <Faq />
            <Team />
        </>
    );
}

export default Home;
