import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
    Container,
    Wrapper,
    ContainerWrapper,
    ImageBox,
    Image,
    Title,
    Description,
    RoadmapWrapper,
    Box,
    DescriptionLight,
    Line,
    BorderLeft,
    BorderRight,
    Youtube,
    GifRight,
    GifLeft,
    FlowerLeft,
    FlowerRight,
    GemGif,
    HitodamaGif,
} from './roadmapStyle';

export const ButtonMap = styled(Link)`
    text-align: center;
    text-decoration: none;
    background-color: #976439;
    padding: 1rem 2rem;
    color: #ffe9bf;
    font-size: 2rem;
    margin: 0.3rem 4rem;

    @media (max-width: 480px) {
        font-size: 1.2rem;
        margin: 0.3rem 1rem;
    }
`;

const Roadmap = () => {
    return (
        <Wrapper id="roadmap">
            <Container>
                <ContainerWrapper>
                    <Line></Line>
                    <ImageBox>
                        <Image
                            src="../../config/images/roadmap-icon.webp"
                            alt="icon kuno"
                        />
                    </ImageBox>
                    <Title>Roadmap</Title>
                    <Description>
                        Kuno is a longterm project, joining the Kuno Secret
                        Society will have utility for early acces to our
                        upcoming Projects. With Anonymous Developer and Design
                        Studio and members from around the globe, we will be
                        hands-on to push this longterm project forward. Each
                        milestone unlocks when the indicated percentage is
                        reached on mint.
                    </Description>

                    <RoadmapWrapper>
                        <Title>Secret Roadmap</Title>
                        <Box>
                            <Description>
                                - Secret Society Vote <br />- Weird Vibe only*{' '}
                                <br />
                            </Description>
                            <DescriptionLight>
                                Charitable Donations <br />
                                Big Reveale after mint 100% Soldout
                            </DescriptionLight>
                        </Box>
                    </RoadmapWrapper>
                    <RoadmapWrapper>
                        <Title>Roadmap V2</Title>
                        <Box>
                            <Description>
                                Longterm Goals (2022 - 2025)
                                <br />
                            </Description>
                            <DescriptionLight>
                                Collab with Big Brands and Influencers <br />{' '}
                                Blokchain Experimental Game <br />
                                Sandbox Game
                            </DescriptionLight>
                            <ButtonMap to="/fullmap">See Full Map</ButtonMap>
                        </Box>
                    </RoadmapWrapper>
                    <Youtube
                        title="kuno universe"
                        src="https://www.youtube.com/embed/sCV40FmhqC0"
                    ></Youtube>

                    <Image
                        style={{ marginTop: '5rem', width: '100%' }}
                        src="../../config/images/Divider.webp"
                        alt="Divider"
                    />
                </ContainerWrapper>
            </Container>
            {/* borders */}
            <BorderLeft
                top={'10px'}
                src="../../config/images/top-left.webp"
                alt="corner image"
            />
            <BorderRight
                top={'10px'}
                src="../../config/images/top-right.webp"
                alt="corner image"
            />

            {/* Gif */}

            <GemGif src="/config/images/gem.gif" alt="Gif" />
            <HitodamaGif src="/config/images/hitodama.gif" alt="Gif" />

            {/* Flower */}

            <FlowerLeft
                src="../../config/images/flower-left.webp"
                alt="flower image kuno"
            />
            <FlowerRight
                src="../../config/images/flower-right.webp"
                alt="flower image kuno"
            />
        </Wrapper>
    );
};

export default Roadmap;
