import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #976439;
`;

export const Container = styled.div`
    padding: 8rem 8rem 6rem 8rem;
    width: 100%;

    @media (max-width: 768px) {
        padding: 2rem 4rem;
    }

    @media (max-width: 500px) {
        padding: 2rem 2rem;
    }
`;

export const ContainerWrapper = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;

export const DescWrapper = styled.div`
    flex-basis: 60%;
    margin-left: auto;
`;

export const Title = styled.p`
    font-size: 65px;
    color: #ffe9bf;

    @media (max-width: 768px) {
        font-size: 50px;
    }

    @media (max-width: 480px) {
        font-size: 40px;
    }
`;

export const Description = styled.p`
    font-size: 32px;
    color: #ffe9bf;
    font-weight: bold;
    @media (max-width: 768px) {
        font-size: 27px;
    }

    @media (max-width: 500px) {
        line-height: 30px;
        font-size: 22px;
    }
`;

export const ImageBox = styled.div`
    // margin-top: 10px;
    flex-basis: 35%;

    @media (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;

export const AboutImage = styled.img`
    width: 100%;
`;

export const KunoImage = styled.video`
    width: 100%;
`;
