import {
    Wrapper,
    Container,
    ContainerWrapper,
    Title,
    Description,
    Bold,
    ImageBox,
    AboutImage,
    DescWrapper,
    KunoImage,
} from './NoteStyle';

const Note = () => {
    return (
        <Wrapper>
            <Container>
                <ContainerWrapper>
                    <ImageBox>
                        <KunoImage autoPlay loop muted playsInline>
                            <source
                                src="/config/images/kuno2.webm"
                                type="video/webm"
                            />
                            <source
                                src="/config/images/kuno2.mp4"
                                type="video/mp4"
                            />
                        </KunoImage>
                    </ImageBox>
                    <DescWrapper style={{ flexBasis: '40%' }}>
                        <Title>Please Note:</Title>
                        <Description>
                            <br />
                            We don’t have Discord at this moment All update will
                            be on Twitter. Be Weird! Stay Anonymous! <br />{' '}
                        </Description>
                        <Description>
                            <br />
                            And stay away from Scammer!
                        </Description>
                    </DescWrapper>
                </ContainerWrapper>
            </Container>
        </Wrapper>
    );
};

export default Note;
