import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #ffe9bf;
    position: relative;
    overflow: hidden;
`;

export const Container = styled.div`
    padding: 0rem 8rem 2rem 8rem;
    width: 100%;

    @media (max-width: 768px) {
        padding: 2rem 4rem;
    }

    @media (max-width: 500px) {
        padding: 0rem 2rem 5rem 2rem;
    }
`;

export const Footer = styled.footer`
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    @media (max-width: 480px) {
        margin-top: 1rem;
        flex-direction: column;
    }
`;

export const FooterLink = styled.a`
    font-weight: bold;
    font-size: 15px;
    text-decoration: none;
    color: #431f11;
    text-align: center;
    text-align-last: center;

    &:nth-child(2) {
        font-size: 19px;

        margin: 0 8rem;
    }

    @media (max-width: 768px) {
        &:nth-child(2) {
            margin: 0 3rem;
        }
    }

    @media (max-width: 480px) {
        margin: 0.3rem;
        &:nth-child(2) {
            margin-top: 1rem;
            font-size: 12px;
            order: 3;
            margin: 0;
        }
    }
`;

export const ContainerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @media (max-width: 768px) {
        // flex-direction: column-reverse;
    }
`;

export const RoadmapWrapper = styled.div`
    margin-top: 5rem;
`;

export const Box = styled.div`
    margin-top: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 480px) {
        margin-top: 2rem;
        flex-direction: column;
    }
`;

export const DescWrapper = styled.div`
    margin-left: auto;
`;

export const Title = styled.p`
    font-family: 'Caesar Dressing', sans-serif;
    font-size: 65px;
    color: #54483e;
    padding-top: 16px;
    text-align: center;
    position: relative;
    z-index: 100;

    @media (max-width: 768px) {
        font-size: 55px;
    }

    @media (max-width: 480px) {
        font-size: 40px;
    }
`;

export const Description = styled.p`
    font-size: 32px;
    color: #431f11;
    font-weight: bold;
    text-align: justify;
    text-align-last: center;
    margin-top: 16px;
    line-height: 50px;
    @media (max-width: 768px) {
        font-size: 27px;
    }

    @media (max-width: 500px) {
        line-height: 30px;
        font-size: 22px;
    }
`;
export const DescriptionLight = styled.p`
    font-size: 32px;
    color: #431f11;
    text-align: justify;
    text-align-last: center;
    line-height: 50px;

    @media (max-width: 768px) {
        font-size: 27px;
    }

    @media (max-width: 500px) {
        line-height: 30px;
        font-size: 22px;
    }
`;

export const ImageBox = styled.div`
    width: 200px;
    text-align: center;

    &:nth-child(2) {
        margin: 0 8rem;
    }

    @media (max-width: 768px) {
        width: 170px;

        margin-top: 20px;
        margin-bottom: 20px;

        &:nth-child(2) {
            margin: 0 5rem;
        }
    }

    @media (max-width: 768px) {
        width: 250px;
    }
`;

export const Image = styled.img`
    width: 100%;
    border-radius: 100%;
`;

export const Icon = styled.img`
    margin-top: 10rem;
    width: 100px;

    @media (max-width: 480px) {
        margin-top: 3rem;
        width: 80px;
    }
`;

export const Line = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: -1px;

    width: 3px;
    background-color: #f2cd98;
    height: 1000px;
`;

export const BorderLeft = styled.img`
    position: absolute;
    left: 10px;
    top: ${({ top }) => (top ? `${top}` : 'unset')};
    bottom: ${({ bottom }) => (bottom ? `${bottom}` : 'unset')};
    width: 450px;
`;

export const BorderRight = styled.img`
    position: absolute;
    right: 10px;
    top: ${({ top }) => (top ? `${top}` : 'unset')};
    bottom: ${({ bottom }) => (bottom ? `${bottom}` : 'unset')};
    width: 450px;
`;
