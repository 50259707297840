import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #ffe9bf;
`;

export const Container = styled.div`
    padding: 0rem 8rem 5rem 8rem;
    width: 100%;

    @media (max-width: 768px) {
        padding: 2rem 4rem;
    }

    @media (max-width: 500px) {
        padding: 0rem 2rem 2rem 2rem;
    }
`;

export const ContainerWrapper = styled.div`
    display: flex;

    flex-direction: column;
    position: relative;

    @media (max-width: 768px) {
        // flex-direction: column-reverse;
    }
`;

export const RoadmapWrapper = styled.div`
    margin-top: 5rem;
`;

export const Box = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DescWrapper = styled.div`
    margin-left: auto;
`;

export const Title = styled.p`
    font-family: 'Caesar Dressing', sans-serif;
    font-size: 65px;
    color: #54483e;
    padding-top: 16px;
    margin-bottom: 4rem;
    text-align: center;
    position: relative;
    z-index: 100;

    @media (max-width: 768px) {
        font-size: 55px;
    }

    @media (max-width: 480px) {
        font-size: 40px;
    }
`;

export const Description = styled.p`
    font-size: 32px;
    color: #431f11;
    font-weight: bold;

    margin-top: 16px;
    line-height: 50px;
    @media (max-width: 768px) {
        font-size: 27px;
    }

    @media (max-width: 500px) {
        line-height: 30px;
        font-size: 20px;
    }
`;
export const DescriptionLight = styled.p`
    font-size: 32px;
    color: #431f11;

    line-height: 50px;

    @media (max-width: 768px) {
        font-size: 27px;
    }

    @media (max-width: 500px) {
        line-height: 30px;
        font-size: 18px;
    }
`;

export const ImageBox = styled.div`
    // flex-basis: 35%;
    width: 150px;

    @media (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;

export const Image = styled.img`
    width: 33px;
    height: 100%;
    cursor: pointer;

    @media (max-width: 480px) {
        width: 18px;
        margin-top: 10px;
    }
`;

export const Line = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: -1px;

    width: 3px;
    background-color: #f2cd98;
    height: 1000px;
`;
