import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #ffe9bf;
    position: relative;
    overflow: hidden;
`;

export const Container = styled.div`
    padding: 2rem 8rem 10rem 8rem;
    width: 100%;

    @media (max-width: 768px) {
        padding: 2rem 4rem;
    }

    @media (max-width: 500px) {
        padding: 2rem 2rem 6rem 2rem;
    }
`;

export const ContainerWrapper = styled.div`
    display: flex;
    margin-top: 70px;

    @media (max-width: 768px) {
        margin-top: 40px;
        flex-direction: column-reverse;
    }
`;

export const Title = styled.p`
    font-family: 'Caesar Dressing', sans-serif;
    font-size: 65px;
    color: #54483e;
    padding-top: 50px;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 55px;
    }

    @media (max-width: 480px) {
        font-size: 40px;
    }
`;

export const Description = styled.p`
    flex-basis: 60%;
    font-size: 32px;
    line-height: 50px;
    color: #431f11;
    text-align: justify;
    position: relative;
    z-index: 1000;

    @media (max-width: 768px) {
        font-size: 27px;
    }

    @media (max-width: 500px) {
        line-height: 30px;
        font-size: 22px;
    }
`;

export const Bold = styled.span`
    font-size: 32px;
    font-weight: bold;
`;

export const ImageBox = styled.div`
    margin-top: 10px;
    margin-left: auto;
    flex-basis: 35%;

    @media (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;

export const AboutImage = styled.img`
    width: 100%;
    // height: 100%;
`;

export const KunoImage = styled.video`
    width: 100%;
    // height: 100%;
`;

export const BorderLeft = styled.img`
    position: absolute;
    left: 10px;
    top: ${({ top }) => (top ? `${top}` : 'unset')};
    bottom: ${({ bottom }) => (bottom ? `${bottom}` : 'unset')};
    width: 450px;
`;

export const BorderRight = styled.img`
    position: absolute;
    right: 10px;
    top: ${({ top }) => (top ? `${top}` : 'unset')};
    bottom: ${({ bottom }) => (bottom ? `${bottom}` : 'unset')};
    width: 450px;
`;
