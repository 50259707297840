import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import { Link } from 'react-router-dom';
import './styles/fullmapStyle.css';

export const Wrapper = styled.div`
    position: relative;
    // background: #ffe8be;
    z-index: 1;
    overflow: hidden;

    @media (max-width: 550px) {
        overflow: auto;
    }
`;

export const Container = styled.div`
    // background-image: url('./images/map.webp');
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;
    // height: 1000px;

    z-index: 2;

    @media (max-width: 550px) {
        width: auto;
    }
`;

export const Video = styled.video`
    z-index: 2;
    object-fit: contain;
    width: 100vw;

    filter: brightness(1.05);

    @media (max-width: 550px) {
        object-fit: cover;

        width: 1400px;
        // height: 100vh;
        // center center
    }
`;

export const LinkBack = styled(Link)`
    font-family: 'Caesar Dressing', sans-serif;
    width: 250px;
    text-align: center;
    font-size: 40px;
    text-decoration: none;
    color: #554a40;
    background-color: #ffeabf;
    border: 4px solid #554a40;
    transition: all 0.4s;

    position: absolute;
    top: 20px;
    left: 20px;

    z-index: 3;
    &:hover {
        background-color: #554a40;
        color: #ffeabf;
    }

    @media (max-width: 650px) {
        width: 100px;
        font-size: 20px;
        border: 2px solid #554a40;
    }
`;

const FullMap = () => {
    let audio = new Audio('/config/images/music.mp3');
    audio.loop = true;

    const start = () => {
        audio.play();
    };

    const pause = () => {
        audio.pause();
    };

    useEffect(() => {
        start();
        window.scrollTo(0, 0);

        return () => pause();
    }, []);

    return (
        <Wrapper>
            <Container>
                <LinkBack onClick={pause} to="/">
                    Back Home
                </LinkBack>
                {/* <Image src="/config/images/map.webp" />
                 */}
                <Video autoPlay loop muted playsInline>
                    <source
                        src="/config/images/kuno-map.webm"
                        type="video/webm"
                    />
                    <source
                        src="/config/images/kuno-map.mp4"
                        type="video/mp4"
                    />
                </Video>
            </Container>
            <img
                className="cloud-1"
                src="/config/images/cloud-1.png"
                alt="cloud"
            />
            <img
                className="cloud-2"
                src="/config/images/cloud-2.png"
                alt="cloud"
            />
            <img
                className="cloud-3"
                src="/config/images/cloud-3.png"
                alt="cloud"
            />
            <img
                className="cloud-4"
                src="/config/images/cloud-4.png"
                alt="cloud"
            />

            <img
                className="cloud-small1"
                src="/config/images/cloud-1.png"
                alt="cloud"
            />

            <img
                className="cloud-small2"
                src="/config/images/cloud-1.png"
                alt="cloud"
            />
        </Wrapper>
    );
};

export default FullMap;
