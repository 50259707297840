import React from 'react';
import { BorderLeft } from '../roadmap/roadmapStyle';
import {
    Container,
    Wrapper,
    ContainerWrapper,
    ImageBox,
    Image,
    Title,
    Description,
    RoadmapWrapper,
    Box,
    DescriptionLight,
    Line,
    BorderRight,
    Icon,
    Footer,
    FooterLink,
} from './teamStyle';

const Team = () => {
    return (
        <Wrapper>
            <BorderLeft
                bottom={'10px'}
                src="../../config/images/bottom-left.webp"
                alt="kuno border design"
            />
            <BorderRight
                bottom={'10px'}
                src="../../config/images/bottom-right.webp"
                alt="kuno border design"
            />
            <Container>
                <ContainerWrapper>
                    <Title>THE TEAM</Title>
                    <Box>
                        <ImageBox>
                            <Image
                                src="../../config/images/footer-image-(3).webp"
                                alt="kuno team"
                            />
                            <Description>ImOkvibe</Description>
                        </ImageBox>
                        <ImageBox>
                            <Image
                                src="../../config/images/footer-image-(2).webp"
                                alt="kuno team"
                            />
                            <Description>TheancientOne</Description>
                        </ImageBox>
                        <ImageBox>
                            <Image
                                src="../../config/images/footer-image-(1).webp"
                                alt="kuno team"
                            />
                            <Description>JRNY.Shadow</Description>
                        </ImageBox>
                    </Box>
                    <Icon src="../../config/images/team-icon.webp" />
                    <Footer>
                        <FooterLink href="https://irp.cdn-website.com/f2fbbcf6/files/uploaded/Risk%20Factos.docx.pdf">
                            Terms & Privacy
                        </FooterLink>
                        <FooterLink href="#" style={{ cursor: 'default' }}>
                            Kuno Spirit world © 2022 All Rights Reserved
                        </FooterLink>
                        <FooterLink href="https://irp.cdn-website.com/f2fbbcf6/files/uploaded/Risk%20Factos.docx.pdf">
                            Risk Factors{' '}
                        </FooterLink>
                    </Footer>
                </ContainerWrapper>
            </Container>
        </Wrapper>
    );
};

export default Team;
