import styled from "styled-components";

export const Wrapper = styled.div`
    background-color: #ffe9bf;
    position: relative;
    overflow: hidden;
`;

export const Container = styled.div`
    padding: 8rem 8rem 5rem 8rem;
    width: 100%;

    @media (max-width: 768px) {
        padding: 2rem 4rem;
    }

    @media (max-width: 500px) {
        padding: 2rem 2rem;
    }
`;

export const ContainerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @media (max-width: 768px) {
        // flex-direction: column-reverse;
    }
`;

export const RoadmapWrapper = styled.div`
    margin-top: 5rem;
`;

export const Box = styled.div`
    border: 3px solid #f2cd98;
    background-color: #ffe9c5;

    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    margin-top: 3rem;
    width: 720px;
    position: relative;
    z-index: 100;

    @media (max-width: 480px) {
        width: 350px;
    }
`;

export const DescWrapper = styled.div`
    // flex-basis: 60%;
    margin-left: auto;
`;

export const Title = styled.p`
    font-family: "Caesar Dressing", sans-serif;
    font-size: 65px;
    color: #54483e;
    padding-top: 16px;
    text-align: center;
    position: relative;
    z-index: 100;

    @media (max-width: 768px) {
        font-size: 55px;
    }

    @media (max-width: 480px) {
        font-size: 40px;
    }
`;

export const Description = styled.p`
    font-size: 32px;
    color: #431f11;
    font-weight: bold;
    text-align: justify;
    text-align-last: center;
    margin-top: 16px;
    line-height: 50px;
    @media (max-width: 768px) {
        font-size: 27px;
    }

    @media (max-width: 500px) {
        line-height: 30px;
        font-size: 22px;
    }
`;
export const DescriptionLight = styled.p`
    font-size: 32px;
    color: #431f11;
    text-align: justify;
    text-align-last: center;
    line-height: 50px;

    @media (max-width: 768px) {
        font-size: 27px;
    }

    @media (max-width: 500px) {
        line-height: 30px;
        font-size: 20px;
        text-align: center;
    }
`;

export const ImageBox = styled.div`
    // flex-basis: 35%;
    width: 150px;

    @media (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @media (max-width: 480px) {
        width: 100px;

        margin-top: 20px;
        margin-bottom: 0px;
    }
`;

export const Image = styled.img`
    width: 100%;
`;

export const Line = styled.div`
    position: absolute;
    bottom: 130px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: -1px;

    width: 3px;
    background-color: #f2cd98;
    height: 1000px;
`;

export const Youtube = styled.iframe`
    width: 1000px;
    height: 645px;
    padding-top: 5rem;
    position: relative;
    z-index: 100;

    @media (max-width: 1000px) {
        width: 700px;
        height: 500px;
    }

    @media (max-width: 480px) {
        width: 350px;
        height: 300px;
    }
`;

export const BorderLeft = styled.img`
    position: absolute;
    left: ${({ left }) => (left ? `${left}` : "10px")};
    top: ${({ top }) => (top ? `${top}` : "unset")};
    bottom: ${({ bottom }) => (bottom ? `${bottom}` : "unset")};
    width: ${({ width }) => (width ? `${width}` : "450px")};
`;

export const BorderRight = styled.img`
    position: absolute;
    right: ${({ right }) => (right ? `${right}` : "10px")};
    top: ${({ top }) => (top ? `${top}` : "unset")};
    bottom: ${({ bottom }) => (bottom ? `${bottom}` : "unset")};
    width: ${({ width }) => (width ? `${width}` : "450px")};
`;

export const GemGif = styled.img`
    position: absolute;
    width: 200px;
    top: 700px;
    right: 100px;

    @media (max-width: 768px) {
        top: 600px;
        right: 10px;
        width: 170px;
    }

    @media (max-width: 480px) {
        top: 500px;
        right: 0px;
        width: 100px;
        z-index: 1000;
    }
`;

export const HitodamaGif = styled.img`
    position: absolute;
    width: 180px;
    top: 1180px;

    left: 100px;

    @media (max-width: 768px) {
        top: 1180px;
        left: 10px;
    }

    @media (max-width: 480px) {
        top: 1000px;
        left: -10px;
        width: 80px;

        z-index: 1001;
    }
`;

export const FlowerLeft = styled.img`
    position: absolute;
    width: 220px;
    top: 650px;
    left: -55px;
    z-index: 1001;

    @media (max-width: 768px) {
        left: -55px;
    }

    @media (max-width: 480px) {
        width: 140px;
        top: 500px;
        left: -55px;
    }
`;

export const FlowerRight = styled.img`
    position: absolute;
    width: 220px;
    top: 1250px;
    right: -55px;
    z-index: 1001;

    @media (max-width: 768px) {
        top: 1180px;

        right: -55px;
    }

    @media (max-width: 480px) {
        width: 150px;
        top: 920px;

        right: -55px;
    }
`;
