import styled from 'styled-components';

// Used for wrapping a page component
export const Screen = styled.div`
    background-color: #f1cfa1;
    background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
    background-size: cover;
    background-position: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (min-width: 1600px) {
        height: 1200px;
    }

    @media (min-width: 1200px) {
        height: 1080px;
    }

    @media (max-width: 480px) {
        height: 100vh;

        // background-image: none;
    }
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
    height: 8px;
    width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
    height: 16px;
    width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
    height: 24px;
    width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
    height: 32px;
    width: 32px;
`;

// Used for providing space between components
export const MarginRight = styled.div`
    margin-right: auto;

    @media (max-width: 480px) {
        margin-right: 15px;
    }
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
    display: flex;
    flex: ${({ flex }) => (flex ? flex : 0)};
    flex-direction: ${({ fd }) => (fd ? fd : 'column')};
    justify-content: ${({ jc }) => (jc ? jc : 'flex-start')};
    align-items: ${({ ai }) => (ai ? ai : 'flex-start')};
    background-color: ${({ test }) => (test ? 'pink' : 'none')};
    width: 100%;

    background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
    background-size: cover;
    background-position: center;
    position: relative;
`;

export const ContainerGif = styled.div`
    display: flex;
    flex: 2;
    flex-direction: ${({ fd }) => (fd ? fd : 'column')};
    justify-content: ${({ jc }) => (jc ? jc : 'flex-start')};
    align-items: ${({ ai }) => (ai ? ai : 'flex-start')};
    width: 100%;

    position: relative;

    @media (max-width: 900px) {
        flex: 0;
    }

    @media (max-width: 768px) {
        flex: 1;
    }

    @media (max-width: 700px) {
        flex: 0;
    }
`;

export const ContainerMinting = styled.div`
    display: flex;
    flex: ${({ flex }) => (flex ? flex : 0)};
    flex-direction: ${({ fd }) => (fd ? fd : 'column')};
    justify-content: ${({ jc }) => (jc ? jc : 'flex-start')};
    align-items: ${({ ai }) => (ai ? ai : 'flex-start')};
    background-color: ${({ test }) => (test ? 'pink' : 'none')};
    width: 100%;
    height: 450px;
    background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
    background-size: cover;
    background-position: center;
    position: relative;
    margin-top: 8rem;
    margin-bottom: 4rem;
    margin-right: 4rem;
    margin-left: 4rem;
    // ss
    background-color: #ffeabf;
    border-radius: 25px;
    border: 5px solid #4e392d;

    @media (max-width: 768px) {
        height: 350px;
    }
    @media (max-width: 480px) {
        border: 3px solid #4e392d;
        height: 400px;

        margin-right: 0rem;
        margin-left: 0rem;
        margin-top: 5rem;
        position: relative;
    }
`;

export const MintingBackground = styled.img`
    width: 830px;
    height: auto;
    position: absolute;
    top: -135px;
    display: none;

    @media (max-width: 768px) {
        display: none;
        top: 0px;

        width: 500px;
        height: auto;
    }

    @media (max-width: 480px) {
        display: none;
        top: 0px;

        width: 400px;
        height: 600px;
    }
`;

export const TextTitle = styled.p`
    font-size: 40px;
    line-height: 1.6;
    position: relative;
    z-index: 1000;

    @media (max-width: 768px) {
        font-size: 35px;
    }

    @media (max-width: 480px) {
        font-size: 23px;
    }
`;

export const MobileTitle = styled.p`
    font-size: 27px;
    line-height: 1;
    position: relative;

    margin: 4.5rem 0 -10px;
    z-index: 1000;

    @media (min-width: 480px) {
        display: none;
    }
`;

export const TextDescription = styled.p`
    font-size: 28px;
    line-height: 1.6;
    position: relative;
    z-index: 1000;

    @media (max-width: 768px) {
        font-size: 19px;
    }

    @media (max-width: 480px) {
        font-size: 18px;
    }
`;

export const TextSubTitle = styled.p`
    font-size: 18px;
    line-height: 1.6;
    position: relative;
    z-index: 1000;
`;

export const StyledClickable = styled.div`
    :active {
        opacity: 0.6;
    }
    position: relative;
    z-index: 1000;
`;
