import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const Container = styled.div`
    background: #88a792;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0;
`;

export const Image = styled.img`
    width: 700px;

    @media (max-width: 650px) {
        width: 350px;
    }
`;

export const LinkBack = styled(Link)`
    font-family: 'Caesar Dressing', sans-serif;
    text-align: center;
    font-size: 40px;
    text-decoration: none;
    color: #554a40;
    transition: all 0.4s;

    &:hover {
        color: #fff;
    }
`;

const NotFound = () => {
    return (
        <Container>
            <Image src="/config/images/404.png" alt="" />
            <LinkBack to="/">
                sORRY, pAGE NOT FOUND <br /> bACK hOME
            </LinkBack>
        </Container>
    );
};

export default NotFound;
